// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "D_f2";
export var multiVendorBusinessesSection = "D_fW";
export var multiVendorClinetsSection = "D_f7";
export var multiVendorDevExpertiseSection = "D_f4";
export var multiVendorDiscoverWorksSection = "D_f1";
export var multiVendorFeaturesSection = "D_fV";
export var multiVendorIdeaToLifeSection = "D_f0";
export var multiVendorIndustriesSection = "D_fX";
export var multiVendorOurAchievementsSection = "D_f6";
export var multiVendorProcessSection = "D_fY";
export var multiVendorProjLogoSection = "D_f3";
export var multiVendorServicesSection = "D_fT";
export var multiVendorSuccessStoriesSection = "D_f5";
export var multiVendorTechStackSection = "D_fZ";